/*------------------------------------*\
        $Minimal Reset
\*------------------------------------*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
}

img,
svg {
    vertical-align: top;
}

/*------------------------------------*\
        $Defaults
\*------------------------------------*/

html {
    font-size: clamp(100%, 50% + 0.666vw, 125%);
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #f7f6f2;
    color: #2d2b24;
    overflow-x: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    
}

/*------------------------------------*\
        $Scroll bar
\*------------------------------------*/
::-webkit-scrollbar-thumb:vertical {
    background-color: #2D2B24;
    height: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: #2D2B24;
    height: 16px !important;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

/*------------------------------------*\
        $Fonts
\*------------------------------------*/
@font-face {
    font-family: 'Graphik';
    src: url('fonts/Graphik-Regular-Web.woff2') format('woff2'),
        url('fonts/Graphik-Regular-Web.woff') format('woff');
}
